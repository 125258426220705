/*
*
* Footers
*/

//
// Footer Classic
// Box Footer
// Footer Social List
// Footer Modern
// Footer Corporate
// Footer Minimal
//



//
// Box Footer
//
.box-footer-small {
	padding-top: 45px;
}

.footer-brand + p {	margin-top: 22px; }

@media (min-width: 480px) {
	.box-footer {
		padding: 40px 30px;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.box-footer {
		* + .rd-form { margin-top: 8px; }
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.box-footer {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (min-width: 1800px) {
	.box-footer {
		padding: 60px 60px 50px;
	}
}


.box-footer-form {
	padding: 30px 20px;
	background: $primary;

	.form-input {
		min-height: 50px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.form-label {
		top: 25px;
	}

	textarea.form-input {
		height: 116px;
		max-height: round(116px * 1.7);
	}

	.button {
		min-width: auto;
		padding-left: 20px;
		padding-right: 20px;
	}

	.form-wrap + * { margin-top: 10px; }
}

.ios,
.iphone,
.ipad,
.mac-os {
	.box-footer-form .form-label {
		top: 13px;
		transform: none;
	}
}

.box-footer-2-title {
	letter-spacing: .025em;
}

.box-footer-2-text {
	letter-spacing: .025em;
	color: $gray-500;
}

* + .box-footer-2-text { margin-top: 8px; }

// Medium ≥768px
@include media-breakpoint-up(md) {
	.box-footer-2 { border: none; }
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.box-footer-2 {
		padding: 35px 30px 25px;

		.footer-contacts {
			li + li { margin-top: 28px; }
		}
	}

	.box-footer-form { padding: 35px 25px; }

	.box-footer-2-text + .footer-contacts { margin-top: 40px; }
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.box-footer-2-text + .footer-contacts { margin-top: 50px; } 
}


//
// Footer Variant 2
//

.footer-variant-2 {
	text-align: center;
	letter-spacing: 0;

	.form-label,
	.form-input { text-align: center; }

	.rights {	font-size: 14px; }

	.rd-form {
		* + .button {	margin-top: 10px; }
	}

	* + .rd-form { margin-top: 25px; }
	* + .group-lg { margin-top: 30px; }
	* + .row {margin-top: 24px;}

	* + .footer-contacts {
		margin-top: 22px;
		padding-top: 32px;
		border-top: 1px solid $gray-200;
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

.footer-variant-2-content {
	padding: 50px 0;

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding-top: 70px;
		padding-bottom: 65px;
	}
}

.footer-variant-2-bottom-panel {
	padding: 30px 0;
}

// Footer Modern Contacts
.footer-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;

	.icon {
		vertical-align: middle;
		text-align: left;
		min-width: 21px;
		font-size: 24px;
		line-height: 24px;
		color: $secondary;
	}

	.fa-envelope {font-size: 20px;}
	.fa-clock-o {font-size: 22px;}

	.link-phone {
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: .025em;
	}

	.link-location { max-width: 192px; }

	p {
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: .025em;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		line-height: 1.5;
		@include link($gray-800, $primary);
	}

	li + li {	margin-top: 18px; }
}

* + .footer-contacts { margin-top: 28px; }

.footer-social-list-2 {
	.icon { font-size: 16px; }

	a {
		@include link($gray-800, $secondary);
	}
}



//
// Footer Modern
//
.footer-modern {
	font-size: 16px;
	line-height: 1.5;
	background: $gray-600;

	.footer-variant-2-bottom-panel {
		background: $gray-700;
	}
}


// Footer Link List
.footer-link-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	@include group(20px, 5px);

	a {
		@include link($gray-800, $secondary);
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		justify-content: flex-start;
	}

	// Medium ≥992px
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
}


.section-prefooter {
	position: relative;

	.rd-google-map { height: 250px; }

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.rd-google-map-wrap {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.rd-google-map {
			width: inherit;
			height: inherit;
		}
	}
}