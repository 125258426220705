/*
*
* Typography
*/

//
// Body
//

body {
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: $font-weight-base;
	letter-spacing: 0;
	color: $body-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

//
// Headings
//
#{headings()} {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: $headings-font-weight;
	font-family: $headings-font-family;
	letter-spacing: .05em;
	color: $headings-color;

	a {
		color: inherit;

		&:hover {
			color: $primary;
		}
	}
}

h1,
.heading-1 {
	font-size: 50px;
	line-height: 0.88;
	letter-spacing: .075em;

	@include media-breakpoint-up(md) {
		font-size: 70px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 90px;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2,
.heading-2 {
	font-size: 36px;
	line-height: 1;
	letter-spacing: 0.005em;

	@include media-breakpoint-up(md) {
		font-size: 50px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 60px;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3,
.heading-3 {
	font-size: 32px;
	line-height: 0.88;
	font-weight: 300;
	letter-spacing: 0.025em;

	@include media-breakpoint-up(md) {
		font-size: 40px;
	}

	@include media-breakpoint-up(lg) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
}

h4,
.heading-4 {
	font-size: 28px;
	line-height: 1;
	letter-spacing: 0;
	font-family: $font-family-base;

	@include media-breakpoint-up(md) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.heading-5 {
	font-size: 20px;
	font-family: $font-family-base;
	line-height: $h5-line-height;
	letter-spacing: 0;

	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
	}
}

h6,
.heading-6 {
	font-size: $h6-font-size;
	font-weight: 500;
	font-family: $font-family-base;
	line-height: $h6-line-height;
	letter-spacing: .01em;
}

//
// Emphasis
//
small,
.small {
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-sm;
}

mark,
.mark {
	padding: $mark-padding;
	color: $mark-color;
	background: $mark-bg;
}

// Big text
.big {
	font-size: $font-size-lg;
	line-height: $line-height-lg;
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
	font-weight: $lead-font-weight;
}

code {
	padding: $code-padding-y $code-padding-x;
	border-radius: $border-radius-sm;
	font-size: $code-font-size;
	color: $code-color;
	background: $code-bg;
}

//
// Paragraph
//
p {
	[data-toggle='tooltip'] {
		color: $gray-400;
	}

	[style*='max-width'] {
		display: inline-block;
	}
}

