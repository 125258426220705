/*
*
* Video BG
* --------------------------------------------------
*/

.bg-vide {
  position: relative;
  z-index: 0;

  > * {
    position: relative;
    z-index: 2;
  }

  > div:not(.container) { pointer-events: none; }
  
  &::before { z-index: 1; }
}


// Youtube Video
.video-background {
  position: relative;
  overflow: hidden;
  background-color: $gray-800;
}


.video-background-poster {
  display: none;
}

.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-foreground {
  height: 300%;
  top: -100%;
  iframe {
    left: -5px;
    width: calc(100% + 5px);
  }
}

.video-background-inner {
  position: relative;
  z-index: 1;
}

[data-x-mode="true"],
.builder,
.ios,
.ipad,
.tablet,
.devices,
.mobile {
  .video-foreground {
    display: none;
  }
  .video-background-poster {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}
