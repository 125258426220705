// Single side border-radius

@mixin border-radius($radius: $border-radius) {
	@if $enable-rounded {
		border-radius: $radius;
	}
}

@mixin border-top-radius($radius) {
	@if $enable-rounded {
		border-top-left-radius: $radius;
		border-top-right-radius: $radius;
	}
}

@mixin border-right-radius($radius) {
	@if $enable-rounded {
		border-top-right-radius: $radius;
		border-bottom-right-radius: $radius;
	}
}

@mixin border-bottom-radius($radius) {
	@if $enable-rounded {
		border-bottom-right-radius: $radius;
		border-bottom-left-radius: $radius;
	}
}

@mixin border-left-radius($radius) {
	@if $enable-rounded {
		border-top-left-radius: $radius;
		border-bottom-left-radius: $radius;
	}
}
