/*
*
* Headings
*/

//
// Table of Contents: 
//
// Title Classic
// Title Modern
// Title Creative
//


//
// Title Modern
//
.title-modern {
  letter-spacing: .05em;

  > span {
    position: absolute;
    left: 50%;
    font-size: 3.75em;
    line-height: 1;
    letter-spacing: .07em;
    color: rgba($white, .07);
    transform: translate3d(-50%, 0, 0);
    will-change: transform;
    animation: titleColor 3s linear infinite;
  }
}

h1.title-modern > span {
  top: -65px;
  font-size: 3.75em;

  // Medium ≥768px
  @include media-breakpoint-up(md) {
    top: -85px;
  }

  // Extra large ≥992px
  @include media-breakpoint-up(lg) {
    top: -110px;
  }
}

h2.title-modern {
  font-weight: 300;

  > span {
    top: -70px;
    font-size: 5.86em;

    // Medium ≥768px
    @include media-breakpoint-up(md) {
      top: -90px;
    }

    // Extra large ≥992px
    @include media-breakpoint-up(lg) {
      top: -120px;
    }

    // Extra large ≥992px
    @include media-breakpoint-up(xl) {
      top: -135px;
    }
  }
}

@keyframes titleColor {
  50% {
    color: rgba($white, .3);
  }
  100% {
    color: rgba($white, .07);
  }
}

// Title Custom
.title-opacity-9 {
  color: rgba($gray-800, .09);
}
