/*
*
* Images
*/

// Standard img style
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-responsive {
	width: 100%;
}

.img-circles {
	border-radius: 50%;
}
