/*
*
* Thumbnails
*/

// 
// Table of Contents
// 
// Thumbnail Classic
// Thumbnail Modern
// Thumbnail Creative
// Thumbnail Mary
// Thumbnail Joan
// Thumbnail Instafeed
// Single Project
// Project Navigation
//


.thumbnail {
	position: relative;
}

.thumbnail-xxs {
	max-width: 220px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-xs {
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-sm {
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-md {
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-lg {
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-xl {
	max-width: 870px;
	margin-left: auto;
	margin-right: auto;
}

//
// Thumbnail Mary
//
.thumbnail-mary {
	text-align: center;
	overflow: hidden;
	transition: all .3s ease;

	&::before,
	&::after {
		position: absolute;
		content: '';
		transition: inherit;
		pointer-events: none;
	}

	&::before {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($gray-700, .5);
		z-index: 1;
	}

	&::after {
		display: inline-block;
		top: 10px;
		right: 10px;
		bottom: 10px;
		left: 10px;
		border: 1px solid rgba($white, .5);
		z-index: 1;
	}
}

.thumbnail-mary-2 {
	&::after { display: none; }

	.thumbnail-mary-caption .icon {
		&:hover { color: $primary; }
	}
}

.thumbnail-xxs {
	.thumbnail-mary-caption .icon { font-size: 30px; }
}

.thumbnail-mary-figure {
	display: block;

	img {
		width: 100%;
		transition: all .3s ease;
	}
}

.thumbnail-mary-caption {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;

	> * {
		pointer-events: auto;
	}
}

.thumbnail-mary-caption .icon {
	font-size: 36px;

	img {display: none;}
	@include link($white, rgba($white, .5));
}

.thumbnail-mary-title {
	color: $white;

	a {@include link(inherit, $secondary);}
}

.thumbnail-mary-project {
	font-weight: 400;
}

.thumbnail-mary-time {
	font-weight: 400;
	color: $primary;
}

.owl-timeline {
	.thumbnail-mary-decor {
		display: inline-block;
		position: relative;
		margin: 15px 0 14px;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background: $primary;

		&::before,
		&::after {
			display: inline-block;
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: inherit;
			height: inherit;
			border-radius: inherit;
			opacity: 1;
			transform: none;
			visibility: visible;
		}

		&::before {
			background: rgba($primary, .5);
			animation: item1B 1s ease infinite;
		}

		&::after{
			background: rgba($primary, .3);
			animation: item1A 1s ease infinite;
		}
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.desktop {
		.thumbnail-mary {

			&::before {
				opacity: 0;
				visibility: hidden;
			}

			&::after {
				transform: scale(0.85);
				opacity: 0;
				visibility: hidden;
			}
		}

		.thumbnail-mary-figure {

			img {
				transform: scale(1.2);
				will-change: transform;
			}
		}

		.thumbnail-mary-caption .icon,
		.thumbnail-mary-title {
			opacity: 0;
			visibility: hidden;
			transition: all .3s ease;
		}

		.thumbnail-mary-caption .icon {
			transform: translate3d(0, -10px, 0);
		}

		.thumbnail-mary-title {
			transform: translate3d(0, 10px, 0);
		}

		.thumbnail-mary:hover {

			&::before {
				opacity: 1;
				visibility: visible;
			}

			&::after {
				opacity: 1;
				visibility: visible;
				transform: none;
			}

			.thumbnail-mary-figure {

				img {
					transform: none;
				}
			}

			.icon,
			.thumbnail-mary-title {
				opacity: 1;
				visibility: visible;
				transform: none;
			}
		}

		.owl-timeline:hover {
			.thumbnail-mary-decor {
				&::before,
				&::after {
					animation: none;
				}
			}

			.owl-stage > .owl-item:hover .thumbnail-mary-decor {
				&::before { animation: item1B 1s ease infinite; }
				&::after{ animation: item1A 1s ease infinite; }
			}
		}
	}
}

// item 1
@keyframes item1B {
	25% {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
	100% {
		opacity: 0;
		transform: scale(3);
		visibility: hidden;
	}
}

@keyframes item1A {
	100% {
		opacity: 0;
		transform: scale(4);
		visibility: hidden;
	}
}

* + .thumbnail-mary-title {	margin-top: 16px; }
* + .thumbnail-mary-description {	margin-top: 24px; }

